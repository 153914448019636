import React, { useEffect, useState } from "react";

import Section from "../../../commons/Section";
import CustomSlider, {
  SampleNextArrow,
  SamplePrevArrow,
} from "../../../commons/CustomSlider";
import Card from "./ProductCard";
import ProductCard from "./Card";

const SpecialProductSlider = ({ products, dots }) => {
  const settings = {
    dots: dots ? false : true,
    isFinite: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    className: "center",
    cssEase: "linear",
    autoplay: false,
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          // infinite: true,
          dots: true,
          speed: 400,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          // infinite: true,
          dots: true,
          speed: 400,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          // infinite: true,
          dots: true,
          speed: 400,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          // infinite: true,
          speed: 400,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          // infinite: true,
          speed: 400,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          speed: 400,
        },
      },
      {
        breakpoint: 480,
        settings: {
          speed: 400,
          autoplaySpeed: 400,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: products?.length > 3,
          dots: true,
          autoplay: false,
          // prevArrow: true,
          // nextArrow: true,
        },
      },
    ],
  };

  const totalNumberOfProducts = products.length;

  return (
    <div className="mt-0">
      <CustomSlider count={totalNumberOfProducts} settings={settings}>
        {/* ALL blogS */}
        {products?.map((product, index) => {
          return (
            <div key={index} className="md:p-3">
              <Card product={product} />
            </div>
          );
        })}
      </CustomSlider>
    </div>
  );
};

export default SpecialProductSlider;
