import React from "react";
import { FaPhone } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaAddressBook } from "react-icons/fa6";
import LogoImage from "../../../../images/logo.png";
import Title from "../../../commons/Title";
import Divider from "../../../commons/Divider";
import { Link } from "react-router-dom";
// #ededed
const Info = () => {
  return (
    <div className="w-full  rounded-[15px] bg-white  px-[1rem] py-8 ">
      <div className="flex flex-col gap-[3rem] max-w-[800px] m-auto">
        <div>
          <div className="flex items-center justify-center">
            <img
              src={LogoImage}
              alt="logo"
              className="h-[100px] w-[100px] object-contain"
            />
          </div>
          {/* <div className="text-center text-[2rem] font-bold">Contact us</div>
           */}
          <Title first="Contact" last="Us" />
          <div className="flex justify-center">
            <Divider />
          </div>
          <br />
          <div className="flex items-center justify-center   m-auto">
            <p className="text-center text-sm md:text-md w-full text-gray-700">
              We'd love to hear from you! Whether you have questions, feedback,
              or just want to say hello, feel free to reach out to us. Our team
              is here to assist you.
            </p>
          </div>
        </div>
        <div className="grid md:grid-cols-3 gap-[2rem] lg:gap-[4rem] items-center md:items-start justify-center justify-items-center w-full ">
          <div className="flex items-center justify-center flex-col gap-[0.3rem]">
            <div className="p-4 bg-primary rounded-full">
              <FaPhone className=" text-white text-[2rem]" />
            </div>
            <p className="text-sm font-semibold">Phone</p>
            <Link className="text-sm" to="tel:+61 414 562 018">
              +61 414 562 018
            </Link>
          </div>
          <div className="flex items-center justify-center flex-col gap-[0.3rem]">
            <div className="p-4 bg-primary rounded-full">
              <MdEmail className=" text-white text-[2rem]" />
            </div>
            <p className="text-sm font-semibold">Email</p>
            <Link className="text-sm" to="mailto:info@hungercafe.com">
              info@hungercafe.com
            </Link>
          </div>
          <div className="flex items-center justify-start flex-col gap-[0.3rem] md:w-[300px">
            <div className="p-4 bg-primary rounded-full">
              <FaAddressBook className=" text-white text-[2rem]" />
            </div>
            <p className="text-sm font-semibold">Address</p>
            <p className="text-center text-sm">Kathmandu, Nepal</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
