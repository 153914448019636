import React from "react";

import Section from "../../../commons/Section";
import Container from "../../../commons/Container";
import SectionTitle from "../../../commons/SectionTitle";
import Slider from "./Slider";
import Title from "../../../commons/Title";
import { testimonials } from "../../../../data";
import Divider from "../../../commons/Divider";

const Testimonials = ({ testimonials }) => {
  return (
    <Section className="flex flex-col gap-[2rem] bg-gray-100 pb-[4rem] ">
      <Container>
        {/* <SectionTitle className="md:mb-[1rem]">Our Testimonials</SectionTitle>
        < */}
        <Title first="Our " last="Testimonial" />
        <div className="flex justify-center">
          <Divider />
        </div>
        <br />
        <Slider testimonials={testimonials} />
      </Container>
    </Section>
  );
};

export default Testimonials;
