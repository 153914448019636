import Banner from "./Banner";
import LoadingSkeleton from "./LoadingSkeleton";
import "./banner.css";
import React, { useEffect, useRef, useState } from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";

const BannerList = ({ isLoading, banners }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderContainerRef = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider({
    loop: true,
    duration: 18000,
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });

  useEffect(() => {
    let interval;

    function startAutoplay() {
      interval = setInterval(() => {
        instanceRef?.current?.next();
      }, 5000);
    }

    function stopAutoplay() {
      clearInterval(interval);
    }

    if (instanceRef) {
      startAutoplay();

      sliderContainerRef.current.addEventListener("mouseenter", stopAutoplay);
      sliderContainerRef.current.addEventListener("mouseleave", startAutoplay);
    }

    return () => {
      // Cleanup event listeners and interval when component unmounts
      if (sliderContainerRef) {
        // instanceRef?.destroy();
        sliderContainerRef?.current?.removeEventListener(
          "mouseenter",
          stopAutoplay
        );
        sliderContainerRef?.current?.removeEventListener(
          "mouseleave",
          startAutoplay
        );
      }
    };
  }, [instanceRef]);
  return (
    <>
      <div ref={sliderContainerRef} className="navigation-wrapper mt-0">
        {isLoading ? (
          <LoadingSkeleton />
        ) : (
          <div
            ref={sliderRef}
            className="keen-slider transition-all ease-in-out duration-[10000ms] relative">
            {banners.length > 0 &&
              banners?.map((banner, index) => (
                <Banner banner={banner} key={banner.id || index} />
              ))}
          </div>
        )}
        {/* </Container> */}
        {loaded && instanceRef.current && (
          <>
            <Arrow
              left
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.prev()
              }
              disabled={currentSlide === 0}
            />

            <Arrow
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.next()
              }
              disabled={
                currentSlide ===
                instanceRef.current.track.details.slides.length - 1
              }
            />
          </>
        )}
      </div>
      {loaded && instanceRef.current && (
        <div className="dots">
          {[
            ...Array(instanceRef.current.track.details.slides.length).keys(),
          ].map((idx) => {
            return (
              <button
                key={idx}
                onClick={() => {
                  instanceRef.current?.moveToIdx(idx);
                }}
                className={
                  "dot" + (currentSlide === idx ? " active" : "")
                }></button>
            );
          })}
        </div>
      )}
    </>
  );
};

function Arrow(props) {
  const disabled = props.disabled ? " arrow--disabled" : "";
  return (
    <svg
      onClick={props.onClick}
      className={`arrow h-[15px] w-[15px] md:h-[30px] md:w-[30px] ${
        props.left ? "arrow--left" : "arrow--right "
      } ${disabled}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24">
      {props.left && (
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      )}
      {!props.left && (
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
      )}
    </svg>
  );
}

export default BannerList;
