import React, { useEffect, useState } from "react";
import { Dialog, DialogHeader, DialogBody } from "@material-tailwind/react";
import Carousel, { ProductImagesCarousel } from "./Carousel";
import cheerio from "cheerio";
import {
  addToCart,
  selectItemsInCart,
} from "../../../../features/cart/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CustomImage from "../../../commons/CustomImage";

export default function ProductDetailModal({ open, handleOpen, product }) {
  const dispatch = useDispatch();
  const [isAlreadyInCart, setIsAlreadyInCart] = useState(false);
  const cartItems = useSelector(selectItemsInCart);
  useEffect(() => {
    const isInCart = cartItems?.some((cartItem) => {
      return cartItem?.item?.id === product.id;
    });
    setIsAlreadyInCart(isInCart);
  }, [cartItems]);
  const handleAdd = (itemToAdd) => dispatch(addToCart(itemToAdd));

  const description = {
    __html: product?.description,
    // __html: `<h1>hello from desc</h1>`,
  };
  return (
    <>
      <Dialog
        size="sm"
        open={open}
        handler={handleOpen}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        {/* <div className="flex items-center justify-center border-b-2">
          <DialogHeader>{product.name}</DialogHeader>
        </div> */}
        <DialogBody className="grid p-0 md:grid-cols-2 ">
          {/* <ProductImagesCarousel images={images} /> */}
          <div className="w-full  md:h-full object-contain ">
            <CustomImage
              src={product?.photo}
              // src="/hot_pot.jpg"
              className="w-full aspect-video md:h-full object-cover md:rounded-s-lg"
            />
          </div>
          <div className="flex flex-col justify-between p-5">
            <div className="flex flex-col justify-between">
              <span className="font-semibold text-lg text-black">
                {product?.name}
              </span>
              <hr className="h-[3px] bg-black" />
              <br />
              <div
                dangerouslySetInnerHTML={description}
                className="text-black text-sm"
              />
              {/* </p> */}
              <br />
              <p className=" font-semibold">
                {product?.offer_price ? (
                  <div className="flex flex-col">
                    <strike>Price:{product?.price}</strike>
                    <p className="text-green-700">
                      Offer Price: Rs {product?.offer_price}
                    </p>
                  </div>
                ) : (
                  <p>Price: ${product?.price}</p>
                )}
              </p>
            </div>

            {isAlreadyInCart ? (
              <Link
                to="/cart"
                className="max-w-full bg-primary text-secondary p-2 rounded-md text-center w-full"
              >
                Go To Cart
              </Link>
            ) : (
              <button
                onClick={() => handleAdd(product)}
                className="w-full bg-primary text-secondary p-2 rounded-md"
              >
                Add To Cart
              </button>
            )}
          </div>
        </DialogBody>
      </Dialog>
    </>
  );
}
