import React, { Suspense, useState } from "react";
import ProductDetailModal from "./ProductDetailModal";
import { FaCartShopping } from "react-icons/fa6";

const Card = ({ product }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen((cur) => !cur);
  const [showAddButton, setShowAddButton] = useState(false);
  return (
    <Suspense>
      <div className="productcard flex flex-col gap-5 relative h-fit">
        <div className="overflow-hidden rounded-t-lg relative group h-fit">
          <img
            src={product?.photo}
            // src="/hot_pot.jpg"
            alt="product_image"
            className="max-w-full w-full object-cover aspect-square rounded-t-lg  shadow-sm hover:scale-110 hover:duration-300 group-hover:blur-[2px]"
          />
          {!product?.offer && (
            <span
              className={`absolute p-1 px-2 rounded-sm font-semibold top-2 right-1 bg-red-900 text-white w-fit text-xs`}
            >
              Offer
            </span>
          )}
          <button
            onClick={handleOpen}
            className={`absolute flex items-center gap-4 justify-center button-56 text-sm py-3 w-full  top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-secondary transition-all duration-300 scale-0  group-hover:scale-100`}
            size="sm"
          >
            Add To Cart <FaCartShopping color="text-primary" size={30} />
          </button>
        </div>

        <div className="flex flex-col gap-2 px-4 pb-4">
          <div className="flex tems-center justify-between gap-2 ">
            <span className="text-sm tracking-wider font-semibold text-gray-900">
              {product?.name}
            </span>
            {/* <p className="text-gray-900 font-semibold text-sm">
              Rs {product?.price}
            </p> */}
          </div>

          <p className=" font-semibold">
            {product?.offer_price ? (
              <div className="flex items-center justify-between">
                <strike className="text-sm">Price:{product?.price}</strike>
                <p className="text-green-700 text-sm">
                  Offer Price: Rs {product?.offer_price}
                </p>
              </div>
            ) : (
              <p>Price: ${product?.price}</p>
            )}
          </p>
          {/* 
          <div
            className="text-sm flex flex-wrap gap-3"
            dangerouslySetInnerHTML={{ __html: product?.description }}
          ></div> */}
        </div>
      </div>
      {open && (
        <ProductDetailModal
          product={product}
          handleOpen={handleOpen}
          open={open}
        />
      )}
    </Suspense>
  );
};

export default Card;
