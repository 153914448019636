import React, { useEffect, useState } from "react";
import Card from "./Card";
import Section from "../../commons/Section";
import Container from "../../commons/Container";
import List from "./List";
// import { subCategories, products } from "../../../data";
import { DrawerWithNavigation, SideBar } from "./Sidebar";
import { useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { API_PATHS } from "../../../utils/constants";
import Loading from "../../commons/Loading";
import Spinner from "../../commons/Spinner";
import NoData from "../../commons/NoData";
import Banner from "../../commons/Banner";

const Products = () => {
  const { id } = useParams();
  const [currentSubCategory, setCurrentSubCategory] = useState(null);
  const {
    data: subCategories,
    loading: loading1,
    error: error1,
  } = useFetch({ path: `${API_PATHS.GET_SUB_CATEGORIES}&cat=${id}` });

  useEffect(() => {
    setCurrentSubCategory(subCategories?.[0]?.id);
  }, [subCategories]);
  const {
    data: products,
    loading: loading2,
    error: error2,
  } = useFetch({
    path: `${API_PATHS.GET_PRODUCTS}?category=${currentSubCategory}`,
  });

  if (loading1) {
    return <Loading />;
  }

  const isRenderable = (data) => {
    return data?.length > 0 ? true : false;
  };

  return (
    <div>
      <Banner title="Our Menu" breadCrumbs={["Home", "Menu"]} />

      <Section className="py-[2rem] my-0 bg-gray-100 relative">
        <Container className="grid md:grid-cols-5 xl:grid-cols-6 gap-[2rem]  md:gap-[2rem] ">
          <div className="lg:hidden md:hidden sm:block">
            <DrawerWithNavigation
              subCategories={subCategories}
              currentSubCategory={currentSubCategory}
              setCurrentSubCategory={setCurrentSubCategory}
            />
          </div>
          <SideBar
            subCategories={subCategories}
            currentSubCategory={currentSubCategory}
            setCurrentSubCategory={setCurrentSubCategory}
          />
          {loading2 ? (
            <div className="col-span-1 md:col-span-2 lg:col-span-3   w-full flex items-center justify-center">
              <Spinner />
            </div>
          ) : isRenderable(products) ? (
            <List products={products} loading={loading2} />
          ) : (
            <div className=" col-span-1 md:col-span-2 lg:col-span-3   w-full flex items-center justify-center">
              <NoData />
            </div>
          )}{" "}
        </Container>
      </Section>
    </div>
  );
};

export default Products;
