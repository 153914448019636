import React from "react";
import Section from "../../../commons/Section";
import Container from "../../../commons/Container";
import SectionTitle from "../../../commons/SectionTitle";
import ContactForm from "./Form";
import Info from "./Info";
import Map from "./Map";
import FAQCards from "../Faqs";
import Divider from "../../../commons/Divider";
const Contact = () => {
  return (
    <Section
      className="bg-white
    "
    >
      <Container>
        {/* <SectionTitle className="md:mb-6">Contact Us</SectionTitle> */}
        {/* <div className="grid grid-cols-2 gap-3 items-center justify-center">
          <div className="col-span-1 pt-[2rem] h-full ">
            <Map />
          </div>
          <div className="col-span-1">
            <ContactForm />
          </div>
        </div> */}
        <br />
        <br />
        <div className="lg:col-span-2 w-full">
          <Info />
        </div>
      </Container>
    </Section>
  );
};

export default Contact;
