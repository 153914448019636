"use client";
import React from "react";
import Blogs from "../components/Pages/Blogs";
// import { blogs } from "../data";

const BlogsPage = () => {
  return (
    <>
      {/* BANNER */}

      {/* BLOGS  */}
      <Blogs />
    </>
  );
};

export default BlogsPage;
