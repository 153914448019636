import React from "react";
import Section from "../../../commons/Section";
import SectionTitle from "../../../commons/SectionTitle";
import Slider from "./Slider";
import ProductCard from "./Card";
import Title from "../../../commons/Title";
import Divider from "../../../commons/Divider";
const SpecialProducts = ({ products }) => {
  return (
    <Section className="bg-gray-100 pb-[4rem] ">
      <>
        {/* <SectionTitle className="mb-[1rem] md:mb-[1rem] ">
          {" "}
          Our Specials
        </SectionTitle> */}
        <Title first="Our " last="Cuisine" />
        <div className="flex justify-center">
          <Divider />
        </div>
        <br />
        <Slider products={products} dots />
      </>
    </Section>
  );
};

export default SpecialProducts;
