import React from "react";
import LoadingImage from "../../images/logo.png";
const Loading = () => {
  return (
    <div className="h-[90vh] w-[100%] flex items-center justify-center">
      <img
        alt="logo"
        src={LoadingImage}
        className="h-full w-full max-h-[200px] max-w-[200px] object-contain "
      />
    </div>
  );
};

export default Loading;
