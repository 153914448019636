import tw from "tailwind-styled-components";
const Section = tw.div`
    m-auto
    py-[2rem]
    md:py-[2.5rem] 
    xl:py-[3.5rem]
    w-[100%]
    ${(className) => className}

`;
export default Section;
