import React from "react";
import SectionTitle from "../../../commons/SectionTitle";
import Slider from "./Slider";
import Section from "../../../commons/Section";
import Container from "../../../commons/Container";

const Testimonials = ({ testimonials }) => {
  return (
    <Section className="flex flex-col gap-[2rem]  bg-gray-100 pb-[4rem]">
      <Container>
        <SectionTitle className="md:mb-[1rem]">Our Testimonials</SectionTitle>
        <Slider testimonials={testimonials} />
      </Container>
    </Section>
  );
};

export default Testimonials;
