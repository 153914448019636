import {
  Bars3Icon,
  BuildingOfficeIcon,
  HomeIcon,
  MagnifyingGlassIcon,
  PhoneIcon,
  ShoppingBagIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { FaBloggerB, FaCross } from "react-icons/fa";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectItemsInCart } from "../../features/cart/cartSlice";
import {
  closeLocationModal,
  openLocationModal,
  toggleMenu,
} from "../../features/app/appSlice";
import Section from "./Section";
import Container from "./Container";
import Logo from "./Logo";
import { FaCartShopping } from "react-icons/fa6";

const Header = () => {
  const { isMenuOpen, isLocationModalOpen } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const items = useSelector(selectItemsInCart);
  console.log(items, "items");
  const totalItemsCount = items.reduce((count, item) => {
    return item.quantity + count;
  }, 0);

  const router = useLocation();
  const pathname = router.pathname;
  const handleToggleMenu = () => dispatch(toggleMenu());

  return (
    <div className="fixed w-full  bg-gray-100 top-0 shadow-sm  z-[200]  mt-0 lg:mt-0 md:mt-0 h-[80px] ">
      <Container className="flex justify-between items-center h-full">
        <div className="flex justify-between items-center w-full">
          <div className="flex items-center gap-2 md:gap-4">
            <Logo />
          </div>
          <ul className="text-zinc-700 text-sm  gap-2 md:gap-8 lg:gap-[2.5rem] items-center hidden md:flex">
            <li>
              <NavLink
                to=""
                style={({ isActive, isPending, isTransitioning }) => {
                  return {
                    color: isActive ? "red" : "black",
                    viewTransitionName: isTransitioning ? "slide" : "",
                  };
                }}
              >
                <p className="hidden md:block">Home</p>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/about"
                style={({ isActive, isPending, isTransitioning }) => {
                  return {
                    color: isActive ? "red" : "black",
                    viewTransitionName: isTransitioning ? "slide" : "",
                  };
                }}
              >
                {/* <BuildingOfficeIcon className="w-4 h-4 text-gray-700" />{" "} */}
                <p className="hidden md:block">About</p>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/blogs"
                style={({ isActive, isPending, isTransitioning }) => {
                  return {
                    color: isActive ? "red" : "black",
                    viewTransitionName: isTransitioning ? "slide" : "",
                  };
                }}
              >
                {/* <FaBloggerB className="w-4 h-4 text-gray-700" />  */}
                <p>Blogs</p>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                style={({ isActive, isPending, isTransitioning }) => {
                  return {
                    color: isActive ? "red" : "black",
                    viewTransitionName: isTransitioning ? "slide" : "",
                  };
                }}
              >
                {/* <PhoneIcon className="w-4 h-4 text-gray-700" />{" "} */}
                <p className="hidden md:block">Contact</p>
              </NavLink>
            </li>
          </ul>
          <NavLink
            to="/cart"
            className="p-2 relative text-sm text-white md:px-4 hover:bg-gray-50 rounded-md 
              items-center gap-2 lg:flex sm:hidden md:flex hidden"
            style={({ isActive, isPending, isTransitioning }) => {
              return {
                color: isActive ? "red" : "black",
                viewTransitionName: isTransitioning ? "slide" : "",
              };
            }}
          >
            {/* <ShoppingBagIcon className="w-4 h-4 text-gray-700" />{" "} */}
            <div className="flex items-center gap-2 ">
              <FaCartShopping color="text-primary" /> Cart
            </div>
            {
              <p className="absolute -top-1 -right-1 bg-primary text-secondary flex justify-center items-center w-5 h-5 text-xs rounded-full">
                {totalItemsCount}
              </p>
            }
          </NavLink>
        </div>

        {!isMenuOpen ? (
          <div className="shadow-lg transition-all fixed top-full -right-[100%] bg-white h-screen p-4 px-8">
            <>
              <ul className="text-zinc-700 space-y-4">
                <li>
                  <Link
                    to="/search"
                    className="p-2 md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2"
                  >
                    {/* <MagnifyingGlassIcon className="w-4 h-4 text-gray-700" />{" "} */}
                    <p>Search</p>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/"
                    className="p-2 md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2"
                  >
                    {/* <HomeIcon className="w-4 h-4 text-gray-700" /> <p>Home</p> */}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about"
                    className="p-2 md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2"
                  >
                    {/* <BuildingOfficeIcon className="w-4 h-4 text-gray-700" />{" "} */}
                    <p>About</p>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/blogs"
                    className="p-2 md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2"
                  >
                    <FaBloggerB className="w-4 h-4 text-gray-700" />{" "}
                    <p>Blogs</p>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact"
                    className="p-2 md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2"
                  >
                    {/* <PhoneIcon className="w-4 h-4 text-gray-700" />{" "} */}
                    <p>Contact</p>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/cart"
                    className="p-2 relative md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2"
                  >
                    {/* <ShoppingBagIcon className="w-4 h-4 text-gray-700" />{" "} */}
                    <p>Cart</p>
                    {
                      <p className="absolute -top-1 -right-1 bg-primary text-secondary flex justify-center items-center w-5 h-5 text-xs rounded-full">
                        {totalItemsCount}
                      </p>
                    }
                  </Link>
                </li>
              </ul>
            </>
          </div>
        ) : (
          <div className="shadow-lg transition-all md:hidden absolute top-full right-0 bg-white h-screen pt-[1rem]  w-[250px] ">
            <>
              <ul className="text-zinc-700 space-y-2 w-full ">
                <li className="border-b w-full h-full pl-[1rem]">
                  <Link
                    to="/"
                    className="p-2 md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2"
                  >
                    {/* <HomeIcon className="w-4 h-4 text-gray-700" /> */}
                    <p>Home</p>
                  </Link>
                </li>
                <li className="border-b w-full h-full pl-[1rem]">
                  <Link
                    to="/about"
                    className="p-2 md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2"
                  >
                    {/* <BuildingOfficeIcon className="w-4 h-4 text-gray-700" />{" "} */}
                    <p>About</p>
                  </Link>
                </li>
                <li className="border-b w-full h-full pl-[1rem]">
                  <Link
                    to="/blogs"
                    className="p-2 md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2"
                  >
                    {/* <FaBloggerB className="w-4 h-4 text-gray-700" />{" "} */}
                    <p>Blogs</p>
                  </Link>
                </li>
                <li className="border-b w-full h-full pl-[1rem]">
                  <Link
                    to="/contact"
                    className="p-2 md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2"
                  >
                    {/* <PhoneIcon className="w-4 h-4 text-gray-700" />{" "} */}
                    <p>Contact</p>
                  </Link>
                </li>
                <li className="border-b w-full h-full pl-[1rem]">
                  <Link
                    to="/cart"
                    className="p-2 relative md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2"
                  >
                    {/* <ShoppingBagIcon className="w-4 h-4 text-gray-700" />{" "} */}
                    <p>Cart</p>
                    {
                      <p className="absolute right-2 md:-top-1 md:-right-1 bg-primary text-white flex justify-center items-center w-5 h-5 text-xs rounded-full">
                        {totalItemsCount}
                      </p>
                    }
                  </Link>
                </li>
              </ul>
            </>
          </div>
        )}

        {isMenuOpen ? (
          <button className="block md:hidden" onClick={handleToggleMenu}>
            <XMarkIcon className=" text-red-600 w-6 h-6" />
          </button>
        ) : (
          <button className="block md:hidden" onClick={handleToggleMenu}>
            <Bars3Icon className="w-6 h-6" />
          </button>
        )}
      </Container>
    </div>
  );
};
export default Header;
