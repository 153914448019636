import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React from "react";
import Slider from "react-slick";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import Container from "./Container";

export function SamplePrevArrow(props) {
  const { onClick, className } = props;
  const isDisabled = className?.includes("slick-disabled");
  return (
    <button
      disabled={isDisabled}
      className="disabled:bg-red-300  disabled:cursor-not-allowed first-letter:next z-10  absolute top-[45%]  left-0 md:left-[-40px] lg:-left-[60px] bg-primary w-7 h-7 lg:w-12 lg:h-12  rounded-full flex justify-center items-center text-white "
      onClick={onClick}
    >
      <BsArrowLeft />
    </button>
  );
}

export function SampleNextArrow(props) {
  const { onClick, className } = props;
  const isDisabled = className?.includes("slick-disabled");
  return (
    <button
      disabled={isDisabled}
      className={`disabled:bg-red-300  disabled:cursor-not-allowed next z-10 absolute top-[45%] right-0 md:right-[-40px]  lg:-right-[60px] sm:right-0 bg-primary h-7 w-7 lg:w-12 lg:h-12  rounded-full flex justify-center items-center text-white`}
      onClick={onClick}
    >
      <BsArrowRight />
    </button>
  );
}

const CustomSlider = ({ children, settings, count }) => {
  var allSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 4,
    initialSlide: 0,
    nextArrow: <SampleNextArrow count={count} />,
    prevArrow: <SamplePrevArrow count={count} />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    ...settings,
  };
  return (
    <div className="mt-0 relative mx-0 ">
      <div className="w-full mx-0 md:w-[90%] 2xl:w-[93%] md:mx-auto  ">
        <Slider {...allSettings} className="">
          {children}
        </Slider>
      </div>
    </div>
  );
};

export default CustomSlider;
