import React from "react";

const Title = ({ first, last, gap }) => {
  return (
    <div
      className={`flex justify-center items-center font-semibold text-3xl mb-3 ${
        gap ? "gap-0" : "gap-3"
      }
      `}
    >
      <p>{first}</p>
      <p className="text-primary">{last}</p>
    </div>
  );
};

export default Title;
